var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.tabs, function(tab, tabIndex) {
                    return [
                      (tab["visible"] !== undefined
                      ? tab["visible"]
                      : true)
                        ? _c(
                            "v-tab",
                            {
                              key: "tab-" + tabIndex,
                              class: { active: _vm.activeTab === tab.key },
                              attrs: { href: "#" + tab.key },
                              on: {
                                click: function($event) {
                                  return _vm.onTabClick(tab.key)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(tab.name) + " ")]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm._t(
                "default",
                [_vm._v("OVERWRITE ME: " + _vm._s(_vm.activeTab))],
                null,
                { activeTab: _vm.activeTab, tabs: _vm.tabs }
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }